.MuiSnackbarContent-root {
  background-color: transparent !important;
}

.MuiTableCell-head {
  color: white !important;
}

.MuiTableHead-root {
  background-color: black;
}

.MuiTableSortLabel-root:hover, .MuiTableSortLabel-root.MuiTableSortLabel-active, .MuiTableSortLabel-icon {
  color: unset !important;
}

.loader-backdrop {
  background-color: rgba(0,0,0,.3);
  z-index: 99999;
}

.vertical-inherit {
  vertical-align: inherit;
}

.el-center {
  width: 160px;
  height: 160px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
}

.loader {
  margin: 40px auto;
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #222222; /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.pointer {
  cursor: pointer;
}

/* Strip Table Start */
.strip-table th {
  padding: 5px;
}

.strip-table td {
  padding: 0;
}

.strip-table td select, .strip-table td input {
  height: 26px;
}
/* Strip Table End */